import React from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import './Menu.css';
import './Common.css';
import { FC, useState } from 'react';
import axis from 'axios';
import { Bars } from 'react-loader-spinner'

const Menu: FC = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [imgNo, setImgNo] = useState('1');
    const [name, setName] = useState('名無し');
    const [token, setToken] = useState('')
    var uid = "none";
    var organizationId = "none";
    var os = "none";
    var viewState = false;

    const search = useLocation().search;
    const query = new URLSearchParams(search);
    if (query.has('uid')) {
        uid = query.get('uid') || "none"
    }
    if (query.has('organization_id')) {
        organizationId = query.get('organization_id') || "none"
    }
    if (query.has('os')) {
        os = query.get('os') || "none"
    }

    const navigate = useNavigate()
    const handleUser = () => {
        navigate('/User?organization_id='+organizationId+'&uid='+uid+'&os='+os, {
            state: {
                name: name,
                icon: imgNo,
                uid: uid,
                organizationId: organizationId,
                os: os,
                token: token
            }
        })
    }
    const handleRegister = () => {
        navigate('/Register?organization_id='+organizationId+'&uid='+uid+'&os='+os, {
            state: {
                name: name,
                icon: imgNo,
                uid: uid,
                organizationId: organizationId,
                os: os,
                token: token
            }
        })
    }
    const handleMember = () => {
        navigate('/Member?organization_id='+organizationId+'&uid='+uid+'&os='+os, {
            state: {
                name: name,
                icon: imgNo,
                uid: uid,
                organizationId: organizationId,
                os: os,
                token: token
            }
        })
    }
    const handleHistory = () => {
        navigate('/History?organization_id='+organizationId+'&uid='+uid+'&os='+os, {
            state: {
                name: name,
                icon: imgNo,
                uid: uid,
                organizationId: organizationId,
                os: os,
                token: token
            }
        })
    }

    const { state } = useLocation();

    React.useLayoutEffect(() => {
        if (state != null){
            viewState = state.viewState;
            setImgNo(state.icon);
            setName(state.name);
            setToken(state.token);
        }

        if (query.get('uid') != null && query.get('organization_id') != null && viewState == false) {
            uid = query.get('uid') || "none"
            organizationId = query.get('organization_id') || "none"
            const getUid = 'uid='+uid;
            const getOrganizationId = 'organization_id='+organizationId;
            const getUrl = 'https://security-alarm-api-stg.ap.litcity.ne.jp/users/?'+getOrganizationId+'&'+getUid;

            axis.get(getUrl).then((response) => {
                setIsLoggedIn(false);
                const data = response.data;
                if (data.hasOwnProperty('icon')){
                    setImgNo(data.icon);
                }
                if (data.hasOwnProperty('name')){
                    setName(data.name);
                }
                setToken(data.token);
                console.log(data)
            });
        }else {
            setIsLoggedIn(false);
        }

    }, []);

    return (
        <div className="Menu">
        <header className="Menu-header">
            <div className="Menu-header-title font-navi-title">
                設定
            </div>
        </header>
        {isLoggedIn ? (
            <div className="Member-cover">
                <div className="Member-cover-oval">
                    <Bars
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            </div>
        ) : (
            ""
        )}
        <body className="Menu-body">
            <div className="Menu-title font-contents-title">
                防犯ブザー通知登録
            </div>
            <div className="Menu-contents font-contents-title">
                <div className="Menu-item Menu-item-under" onClick={handleUser}>
                    プロフィール
                </div>
                <div className="Menu-item Menu-item-under" onClick={handleRegister}>
                    通知メンバー登録
                </div>
                <div className="Menu-item Menu-item-under" onClick={handleMember}>
                    登録者一覧
                </div>
                <div className="Menu-item" onClick={handleHistory}>
                    通知履歴
                </div>
            </div>
            <div className="Menu-sub font-contents-sub-body">
                <div>※ 初回アクセス時は、</div>
                <div>　プロフィールをご確認ください。</div>
            </div>
        </body>
    </div>
    );
};
export default Menu;


