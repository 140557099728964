import { useNavigate } from "react-router-dom";
import './Detail.css';
import React from "react";
import { useLocation } from "react-router-dom";
import { FC, useEffect, useState, useRef } from 'react';
import axis from 'axios';
import { Bars } from 'react-loader-spinner'
import { Loader } from "@googlemaps/js-api-loader";

const Detail: FC = () => {
  const [isView, setIsView] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [icon, setIcon] = useState('./images/icon/1.png');
  const [name, setName] = useState('名無し');
  const navigate = useNavigate()
  const { state } = useLocation();
  console.log(state);
  const handleHistory = () => {
    navigate('/History?organization_id='+state.organizationId+'&uid='+state.uid+'&os='+state.os, {
        state: {
            name: state.name,
            icon: state.icon,
            uid: state.uid,
            organizationId: state.organizationId,
            os: state.os,
            token: state.token,
            isAppShow: state.isAppShow || false
        }
    })
  }

  React.useLayoutEffect(() => {
    if (isView==false){
      setIsView(true);
      var getUid = '';
      if (state.type=="送信") {
        getUid = 'uid='+state.uid;
      }else{
        getUid = 'uid='+state.history.uid+'&puid=puid';
      }
      const getOrganizationId = 'organization_id='+state.organizationId;
      const getUrl = 'https://security-alarm-api-stg.ap.litcity.ne.jp/users/?'+getOrganizationId+'&'+getUid;
      axis.get(getUrl).then((response) => {
        const data = response.data;
        console.log(data);
        setIcon('./images/icon/'+data.icon+'.png');
        setName(data.name);

        const markerImg = document.createElement('img');
        markerImg.src = icon;
        markerImg.style.width = '40px';
        markerImg.style.height = '40px';
        markerImg.style.boxShadow = '0px 2px 6px rgba(0, 0, 0, 0.5)';
        markerImg.style.borderRadius = '50%';
        (async () => {
          const [{ Map }, { AdvancedMarkerElement }] = await Promise.all([
            loader.importLibrary("maps"),
            loader.importLibrary("marker")
          ]);
          // 地図の描画
          const map = new Map(mapRef.current!, {
            center: position,
            zoom: 16,
            mapId: "history-detail-map",
          });
          // マーカーの描画
          new AdvancedMarkerElement({
            map: map,
            position: position,
            content: markerImg,
            title: "" });
        })();
      });
    }
  }, []);

  const position = { lat: Number(state.history.notice.latitude), lng: Number(state.history.notice.longitude) };
  const mapRef = useRef<HTMLDivElement>(null);

  let apiKeyValue: string | undefined = process.env.REACT_APP_VITE_MAPS_API_KEY;
  let stringValue: string = apiKeyValue!;
  console.log(position);

  const loader = new Loader({
    apiKey: stringValue,
    version: "weekly",
  });
  // const markerImg = document.createElement('img');
  // markerImg.style.width = '40px';
  // markerImg.style.height = '40px';
  // markerImg.style.boxShadow = '0px 2px 6px rgba(0, 0, 0, 0.5)';
  // markerImg.style.borderRadius = '50%';

  // // 初回レンダリング時に使用するライブラリを非同期でロード
  // useEffect(() => {
  //   (async () => {
  //     const [{ Map }, { AdvancedMarkerElement }] = await Promise.all([
  //       loader.importLibrary("maps"),
  //       loader.importLibrary("marker")
  //     ]);
  //     // 地図の描画
  //     const map = new Map(mapRef.current!, {
  //       center: position,
  //       zoom: 16,
  //       mapId: "history-detail-map",
  //     });
  //     // マーカーの描画
  //     new AdvancedMarkerElement({
  //       map: map,
  //       position: position,
  //       content: markerImg,
  //       title: "" });
  //   })();
  // }, []);

  const type = state.type;
  const date = state.history.send_date;
  const message = state.history.notice.message;

  const handleDelete = () => {
    console.log('削除')
    setIsLoggedIn(true);
    if (type=='送信'){
      if (state.history.receive_delete_flg){
        console.log('送信削除')
        axis.post('https://security-alarm-api-stg.ap.litcity.ne.jp/history/send/delete', {
          uid: state.uid,
          organization_id: state.organizationId,
          order: state.history.order,
        })
        .then(red => {
          setIsLoggedIn(false);
          handleHistory()
        })
        .catch((error) => {
          setIsLoggedIn(false);
          handleShowResultModal()
        });
      }else {
        console.log('送信フラグ')
        axis.post('https://security-alarm-api-stg.ap.litcity.ne.jp/history/send/flg', {
          uid: state.uid,
          organization_id: state.organizationId,
          order: state.history.order,
        })
        .then(red => {
          setIsLoggedIn(false);
          handleHistory()
        })
        .catch((error) => {
          setIsLoggedIn(false);
          handleShowResultModal()
        });
      }
    }else {
      if (state.history.send_delete_flg){
        console.log('受信削除')
        axis.post('https://security-alarm-api-stg.ap.litcity.ne.jp/history/receive/delete', {
          uid: state.uid,
          organization_id: state.organizationId,
          order: state.history.order,
        })
        .then(red => {
          setIsLoggedIn(false);
          handleHistory()
        })
        .catch((error) => {
          setIsLoggedIn(false);
          handleShowResultModal()
        });
      }else {
        console.log('受信フラグ')
        axis.post('https://security-alarm-api-stg.ap.litcity.ne.jp/history/receive/flg', {
          uid: state.uid,
          organization_id: state.organizationId,
          order: state.history.order,
        })
        .then(red => {
          setIsLoggedIn(false);
          handleHistory()
        })
        .catch((error) => {
          setIsLoggedIn(false);
          handleShowResultModal()
        });
      }
    }
  }

  const dialogResultRef = useRef<HTMLDialogElement>(null);
  const handleShowResultModal = () => dialogResultRef.current?.showModal();
  const handleCloseResultModal = () => {
    dialogResultRef.current?.close()
  }

  return (
      <div className="Detail">
      <header className="Detail-header">
        <div className="Detail-header-back" onClick={handleHistory}>
          <span className="dli-chevron-round-left"></span>
        </div>
        <div className="Detail-header-title font-navi-title">
          通知履歴
        </div>
      </header>
      {isLoggedIn ? (
            <div className="Detail-cover">
                <div className="Detail-cover-oval">
                    <Bars
                        height="80"
                        width="80"
                        color="#4fa94d"
                        ariaLabel="bars-loading"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                    />
                </div>
            </div>
        ) : (
            ""
        )}
      <body className="Detail-body">
        <div ref={mapRef} className="Detail-map"></div>
        <div className="Detail-status">
          <div className="Detail-user">
            <img src={icon}/>
            <div className="Detail-user-detail font-contents-body">
              <div className="Detail-type">{type}</div>
              <div className="Detail-name">{name}</div>
            </div>
          </div>
          <div className="Detail-date font-contents-title">{date}</div>
          <div className="Detail-message font-contents-title">{message}</div>
        </div>
        <div className="Detail-delete-button" onClick={handleDelete}>
            <img src="./images/delete.png" alt="" />
          </div>
      </body>
      <dialog className="Detail-dialog" ref={dialogResultRef}>
        <div className="Detail-dialog-title font-contents-body">削除に失敗しました</div>
        <div className="Detail-button font-button" onClick={handleCloseResultModal}>
            OK
        </div>
      </dialog>
  </div>
  );
};
export default Detail;


