import { useNavigate } from "react-router-dom";
import './Qrcode.css';
import { useLocation } from "react-router-dom";
import jsQR from 'jsqr'
import { useRef, useState, useEffect, FC } from 'react';
import { useDropzone } from 'react-dropzone';
import axis from 'axios';
import { Oval } from 'react-loader-spinner'
import { transform } from "typescript";

type Props = {}
const Qrcode: FC<Props> = () => {
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const videoRef = useRef<HTMLVideoElement>(null)
    const canvasRef = useRef<HTMLCanvasElement>(null)

    const navigate = useNavigate()
    const { state } = useLocation();
    const handleRegister = () => {
        if (videoRef.current) {
            const currentVideoRef = videoRef.current
            if (currentVideoRef && currentVideoRef.srcObject) {
                const stream = currentVideoRef.srcObject as MediaStream
                const tracks = stream.getTracks()
                tracks.forEach((track) => track.stop())
            }
        }
        navigate('/Register?organization_id='+state.organizationId+'&uid='+state.uid+'&os='+state.os, {
            state: {
                name: state.name,
                icon: state.icon,
                uid: state.uid,
                organizationId: state.organizationId,
                os: state.os,
                token: state.token,
                isAppShow: state.isAppShow || false,
            }
        })
    }

    const handleMember = () => {
        if (videoRef.current) {
            const currentVideoRef = videoRef.current
            if (currentVideoRef && currentVideoRef.srcObject) {
                const stream = currentVideoRef.srcObject as MediaStream
                const tracks = stream.getTracks()
                tracks.forEach((track) => track.stop())
            }
        }
        navigate('/Member?organization_id='+state.organizationId+'&uid='+state.uid+'&os='+state.os, {
            state: {
                name: state.name,
                icon: state.icon,
                uid: state.uid,
                organizationId: state.organizationId,
                os: state.os,
                token: state.token,
                isAppShow: state.isAppShow || false,
            }
        })
    }

    const [result, setResult] = useState<string | undefined>()
    const [message, setMessage] = useState<string | undefined>()

    useEffect(() => {

        const constraints = {
            video: {
                facingMode: 'environment',
                width: { max: 3000 },
                height: { max: 3000 },
            },
        }
        // デバイスのカメラにアクセスする
        navigator.mediaDevices
            .getUserMedia(constraints)
            .then((stream) => {
                // デバイスのカメラにアクセスすることに成功したら、video要素にストリームをセットする
                if (videoRef.current) {
                    videoRef.current.srcObject = stream

                    videoRef.current.onloadedmetadata = () => {
                        videoRef.current!.play()
                        scanQrCode()
                    }
                }
            })
        .catch((err) => console.error('Error accessing media devices:', err))

        const currentVideoRef = videoRef.current

        return () => {
            if (currentVideoRef && currentVideoRef.srcObject) {
                const stream = currentVideoRef.srcObject as MediaStream
                const tracks = stream.getTracks()
                tracks.forEach((track) => track.stop())
            }
        }
    }, [])

    const scanQrCode = () => {
        const canvas = canvasRef.current
        const video = videoRef.current
        if (canvas && video) {
            // const ctx = canvas.getContext('2d')
            const ctx = canvas.getContext('2d', { willReadFrequently: true });
            if (ctx) {
                try {
                    // カメラ映像の準備状態をチェック
                    if (!video || video.readyState !== HTMLMediaElement.HAVE_ENOUGH_DATA) {
                        setMessage('カメラ映像を取得できませんでした');
                        setTimeout(scanQrCode, 100);
                        handleShowModal();
                        return;
                    }

                    // canvasとコンテキストのチェック
                    if (!canvas || !ctx) {
                        setMessage('キャンバスの準備に失敗しました');
                        setTimeout(scanQrCode, 100);
                        handleShowModal();
                        return;
                    }

                    const videoAspectRatio = video.videoWidth / video.videoHeight;
                    const canvasAspectRatio = canvas.width / canvas.height;

                    let sx, sy, sWidth, sHeight;
                    if (videoAspectRatio > canvasAspectRatio) {
                        // 映像の幅が広い場合、横をカット
                        sWidth = video.videoHeight * canvasAspectRatio;
                        sHeight = video.videoHeight;
                        sx = (video.videoWidth - sWidth) / 2;
                        sy = 0;
                    } else {
                        // 映像の高さが高い場合、縦をカット
                        sWidth = video.videoWidth;
                        sHeight = video.videoWidth / canvasAspectRatio;
                        sx = 0;
                        sy = (video.videoHeight - sHeight) / 2;
                    }

                    // アスペクト比を保って高解像度で描画
                    ctx.clearRect(0, 0, canvas.width, canvas.height);
                    ctx.drawImage(video, sx, sy, sWidth, sHeight, 0, 0, canvas.width, canvas.height);

                    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

                    // imageDataのチェック
                    if (!imageData || !imageData.data) {
                        setMessage('画像データの取得に失敗しました');
                        setTimeout(scanQrCode, 100);
                        handleShowModal();
                        return;
                    }

                    // QRコードをスキャンする
                    const qrCodeData = jsQR(imageData.data, imageData.width, imageData.height);
                    if (qrCodeData) {
                        // スキャンされた内容を確認する
                        if (!qrCodeData.data.includes("member_uid=")) {
                            setResult(undefined);
                            setMessage('対応していないQRコードです');
                            setTimeout(scanQrCode, 100);
                            handleShowModal();
                            return;
                        }

                        const getDataString: string = qrCodeData.data;
                        const memberUidString: string = "member_uid=";
                        const getMemberUid: string = getDataString.replace(memberUidString, "");
                        if (getMemberUid == state.uid){
                            setResult(undefined);
                            setMessage('自分のQRコードは登録できません');
                            setTimeout(scanQrCode, 100);
                            handleShowModal();
                            return;
                        }

                        memberRegistration(getMemberUid)
                        return;
                    }
                } catch (error) {
                    setMessage('QRコードの読み取り中にエラーが発生しました');
                    setTimeout(scanQrCode, 100);
                    handleShowModal();
                }
                setTimeout(scanQrCode, 100);
            }
        }
    }

    const onDrop = async (files: File[]) => {
        if (files.length < 1) return setMessage('ファイルを選択されます')
        const file = files[0]
        // 画像の判定
        if (!file.type.includes('image')) return setMessage('QRコード画像を選択してください')
        if (file) {
        const reader = new FileReader()
        reader.onload = (e: ProgressEvent<FileReader>) => {
            if (!e.target) return
            const img = new Image()
            img.onload = () => {
            // キャンバス作成
            const canvas = document.createElement('canvas')
            canvas.width = img.width
            canvas.height = img.height
            const ctx = canvas.getContext('2d')
            if (ctx) {
                // キャンバスに画像を描画
                ctx.drawImage(img, 0, 0)
                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height)

                try {
                    if (!imageData || !imageData.data || !imageData.width || !imageData.height) {
                        setResult(undefined);
                        setMessage('無効な画像データです');
                        handleShowModal();
                        return;
                    }

                    // QRコードをスキャン
                    const qrCodeData = jsQR(imageData.data, imageData.width, imageData.height);

                    if (!qrCodeData) {
                        setResult(undefined);
                        setMessage('QRコードを読み込めませんでした');
                        handleShowModal();
                        return;
                    }

                    if (!qrCodeData.data || !qrCodeData.data.includes("member_uid=")) {
                        setResult(undefined);
                        setMessage('対応していないQRコードです');
                        handleShowModal();
                        return;
                    }

                    setResult(qrCodeData.data);

                    const getDataString: string = qrCodeData.data;
                    const memberUidString: string = "member_uid=";
                    const getMemberUid: string = getDataString.replace(memberUidString, "");
                    if (getMemberUid == state.uid){
                        setResult(undefined);
                        setMessage('自分のQRコードは登録できません');
                        handleShowModal();
                        return;
                    }
                    memberRegistration(getMemberUid)

                } catch (error) {
                    setResult(undefined);
                    setMessage('QRコードの読み取り中にエラーが発生しました');
                    handleShowModal();
                }
            }
            }
            img.src = e.target.result as string
        }
        reader.readAsDataURL(file)
        }
    }

    const { getRootProps, getInputProps } = useDropzone({ onDrop, multiple: false })

    const dialogRef = useRef<HTMLDialogElement>(null);
    const handleShowModal = () => dialogRef.current?.showModal();
    const handleCloseModal = () => {
        dialogRef.current?.close();
        if (message === '登録しました'){
            handleMember();
        }else {
            handleRegister();
        }
    }

    const memberRegistration = (memberUid: string) => {
        setIsLoggedIn(true)
        console.log(state.uid)
        console.log(state.organizationId)
        axis.post('https://security-alarm-api-stg.ap.litcity.ne.jp/regist/', {
            uid: state.uid,
            organization_id: state.organizationId,
            member_uid: memberUid
        })
        .then(red => {
            if (red.status == 200){
                console.log(red.data);
                setIsLoggedIn(false);
                setMessage('登録しました')
                handleShowModal()
            }else{
                setIsLoggedIn(false);
                setMessage('登録に失敗しました')
                handleShowModal()
            }
        })
        .catch((error) => {
            setIsLoggedIn(false);
            setMessage('登録に失敗しました')
            handleShowModal()
        });
    }

    return (
        <div className="Qrcode">
        <header className="Qrcode-header">
            <div className="Qrcode-header-back" onClick={handleRegister}>
                <span className="dli-chevron-round-left"></span>
            </div>
            <div className="Qrcode-header-title font-navi-title">
                通知メンバー登録
            </div>
        </header>
        {isLoggedIn ? (
            <div className="Qrcode-cover">
                <div className="Qrcode-cover-oval">
                    <Oval
                    visible={true}
                    height="80"
                    width="80"
                    color="#4fa94d"
                    ariaLabel="oval-loading"
                    wrapperStyle={{}}
                    wrapperClass=""
                />
                </div>
            </div>
        ) : (
            ""
        )}
        <body className="Qrcode-body">
            <div className='Qrcode-title font-contents-title'>
                QRコードをスキャンしてください
            </div>
                <div className='Qrcode-contents'>
                    <div className='Qrcode-frame'></div>
                    <div className='Qrcode-frame-back'></div>
                    <div className='Qrcode-scan'>
                        <video ref={videoRef} autoPlay playsInline className='Qrcode-video' />
                        <canvas ref={canvasRef} className='Qrcode-canvas' />
                    </div>
                    <div {...getRootProps({ onClick: (event) => console.log("Clicked!") })}>
                        <input {...getInputProps()} />
                        <div className="Qrcode-button font-button">
                            QRコード画像を読み込む
                        </div>
                    </div>
                    <div className=''>
                        ※QRコードを読み取れない場合や近くにいない場合は、受け取ったQRコードを上記のボタンよりアップロードしてください。
                    </div>
                </div>
            <dialog className="Qrcode-dialog" ref={dialogRef}>
                <div className="Qrcode-dialog-title font-contents-body">{message}</div>
                <div className="Qrcode-button font-button" onClick={handleCloseModal}>
                    OK
                </div>
            </dialog>
        </body>
    </div>
    );
};
export default Qrcode;


